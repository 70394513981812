:root {
  --igc-text-color: 'inherit';
  --igc-dock-background: 'transparent';
  --igc-splitter-background: #222222;
  --igc-splitter-handle: 'transparent';

  display: flex;
  flex-direction: column;
  height: 100%;
}

.dockManager {
  height: 100%;
  width: 100%;
}

.content1 {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  display: flex;
  background-color: #2c2c2c;
}

.content2 {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 2;
  background-color: #2c2c2c;
}

igc-dockmanager::part(pane-header) {
  display: none;
}

igc-dockmanager::part(splitter) {
  pointer-events: none;
  cursor: default;
}

.dockManagerFrame {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
