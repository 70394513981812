fieldset {
  border: 0;
  padding: 0;

  legend {
    padding: 0;
  }
}

canvas {
  outline: 0;
}

.pwa-install-popup-ios-content{
  color: #000 !important
}