.my-toolbar {
  border-radius: 4px;
  background-color: #303030;
  border: none;
}

.rdw-option-wrapper {
  background-color: #e0e0e0;
  border-radius: 4px;
}

.rdw-editor-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.editor-label {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

/* HD 720p (1280x720) */
@media only screen and (max-width: 1280px) {
  .rdw-option-wrapper {
    width: 25px;
    height: 25px;
  }
}

/* Full HD 1080p (1920x1080) */
@media only screen and (max-width: 1920px) {
  .rdw-option-wrapper {
    width: 25px;
    height: 25px;
  }
}

/* 1K (1024px) */
@media only screen and (max-width: 1024px) {
  .rdw-option-wrapper {
    width: 25px;
    height: 25px;
  }
}

/* 2K (2048px) */
@media only screen and (max-width: 2048px) {
  .rdw-option-wrapper {
    width: 0.5rem;
    height: 0.5rem;
    min-height: 0.5rem;
    min-width: 0.5rem;
  }
}
